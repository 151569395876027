// z-index: 1040;

// margin-top: -14px;
#customer-support-button {
	color: #777 !important;
	background-color: rgb(240, 240, 240) !important;
	border: 1px solid #333 !important;
	border-radius: 0 !important;
	height: 32px;
	width: 32px;
	font-size: 18px;
	padding-left: 5px;
	line-height: 1;
	margin-right: 15px;

	&:hover {
		color: #333 !important;
		background-color: rgb(230, 230, 230) !important;
	}

	&:active {
		background-color: rgb(255, 255, 255) !important;
	}
}
